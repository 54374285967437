import { ChannelType, ContactPolicyActionStatus, ContactPolicyChannelType } from '../enums';

export const ContactPolicyActionStatusesByChannelType: Record<ContactPolicyChannelType, ContactPolicyActionStatus[]> = {
  [ChannelType.EMAIL]: [
    ContactPolicyActionStatus.CLICKED,
    ContactPolicyActionStatus.DISPATCHED,
    ContactPolicyActionStatus.FEEDBACK_SUBMITTED,
    ContactPolicyActionStatus.OPENED,
    ContactPolicyActionStatus.SENT,
  ],
  [ChannelType.LINK]: [
    ContactPolicyActionStatus.CLICKED,
    ContactPolicyActionStatus.CREATED,
    ContactPolicyActionStatus.DISPATCHED,
    ContactPolicyActionStatus.FEEDBACK_SUBMITTED,
  ],
  [ChannelType.MOBILE]: [ContactPolicyActionStatus.FEEDBACK_SUBMITTED],
  [ChannelType.SMS]: [
    ContactPolicyActionStatus.CLICKED,
    ContactPolicyActionStatus.DISPATCHED,
    ContactPolicyActionStatus.FEEDBACK_SUBMITTED,
    ContactPolicyActionStatus.SENT,
  ],
  [ChannelType.WEB_WIDGET]: [ContactPolicyActionStatus.FEEDBACK_SUBMITTED],
  [ChannelType.WHATSAPP]: [
    ContactPolicyActionStatus.CLICKED,
    ContactPolicyActionStatus.DISPATCHED,
    ContactPolicyActionStatus.FEEDBACK_SUBMITTED,
    ContactPolicyActionStatus.SENT,
  ],
  [ChannelType.PUSH_NOTIFICATION]: [
    ContactPolicyActionStatus.CLICKED,
    ContactPolicyActionStatus.DISPATCHED,
    ContactPolicyActionStatus.FEEDBACK_SUBMITTED,
    ContactPolicyActionStatus.SENT,
  ],
  [ChannelType.KIOSK]: [],
  [ChannelType.CATI]: [],
};
