import { LeoAnalysisType, LeoDashboardAnalysisScope, LeoDashboardAnalysisType, LeoQuestionType } from '../enums';
import { LeoQuestion } from '../interfaces';
import { CustomIconFlow } from '../icons';
import { faChartSimple } from '@fortawesome/pro-light-svg-icons';

// The order of these questions determines the order of the questions in the survey.

export const LeoQuestions: LeoQuestion[] = [
  {
    analysisType: [],
    id: 'analyze-type',
    options: [
      { icon: faChartSimple, value: 'dashboard', permission: 'leo.dashboardAnalysis' },
      { icon: CustomIconFlow, value: 'flow', permission: 'leo.flowAnalysis' },
    ],
    required: true,
    tags: ['analyze-type'],
    type: LeoQuestionType.ANALYSIS_TYPE,
  },
  {
    analysisType: [],
    id: 'analyze-entity',
    required: true,
    tags: ['analyze-entity'],
    type: LeoQuestionType.ANALYZE_ENTITY,
  },
  {
    analysisType: [LeoAnalysisType.DASHBOARD],
    id: 'dashboard-analysis-scope',
    options: Object.values(LeoDashboardAnalysisScope).map((value) => ({ value })),
    required: true,
    tags: ['dashboard-analysis-scope'],
    type: LeoQuestionType.DASHBOARD_ANALYSIS_SCOPE,
  },
  {
    analysisType: [LeoAnalysisType.DASHBOARD],
    id: 'dashboard-widgets',
    required: true,
    tags: ['dashboard-widgets'],
    type: LeoQuestionType.DASHBOARD_WIDGETS,
  },
  {
    analysisType: [LeoAnalysisType.DASHBOARD],
    id: 'dashboard-analysis-type',
    options: Object.values(LeoDashboardAnalysisType).map((value) => ({ value })),
    required: true,
    tags: ['dashboard-analysis-type'],
    type: LeoQuestionType.DASHBOARD_ANALYSIS_TYPE,
  },
  {
    analysisType: [LeoAnalysisType.FLOW],
    id: 'touch-point',
    prompt: 'the touch point is {{answer}}',
    required: false,
    tags: ['touch-point'],
    type: LeoQuestionType.TEXT,
  },
  {
    analysisType: [LeoAnalysisType.FLOW],
    id: 'target-audience',
    prompt: 'targeting {{answer}} customers',
    required: false,
    tags: ['target-audience'],
    type: LeoQuestionType.TEXT,
  },
  {
    analysisType: [LeoAnalysisType.FLOW],
    id: 'channels',
    options: [
      { value: 'cati' },
      { value: 'kiosk' },
      { value: 'link' },
      { value: 'mail' },
      { value: 'mobile' },
      { value: 'sms' },
      { value: 'web' },
      { value: 'whatsapp' },
    ],
    prompt: 'using the {{answer}} channel(s) to communicate with customers',
    required: false,
    tags: ['channels'],
    type: LeoQuestionType.MULTI,
  },
  {
    analysisType: [LeoAnalysisType.FLOW],
    id: 'communication-frequency',
    options: [{ value: 'one-time' }, { value: 'real-time' }], // Only static ones. Question form will add dynamic option.
    prompt: 'the communication frequency will be {{answer}}',
    required: false,
    tags: ['communication-frequency'],
    type: LeoQuestionType.COMMUNICATON_FREQUENCY,
  },
  {
    analysisType: [LeoAnalysisType.FLOW],
    id: 'survey-purpose',
    prompt: 'the purpose of the survey is {{answer}}',
    required: false,
    tags: ['survey-purpose'],
    type: LeoQuestionType.TEXT,
  },
];
