export enum LeoQuestionType {
  ANALYSIS_TYPE = 'analysisType',
  ANALYZE_ENTITY = 'analyzeEntity',
  CHART = 'chart',
  COMMUNICATON_FREQUENCY = 'communicationFrequency',
  DASHBOARD_ANALYSIS_SCOPE = 'dashboardAnalysisScope',
  DASHBOARD_ANALYSIS_TYPE = 'dashboardAnalysisSubtype',
  DASHBOARD_WIDGETS = 'dashboardWidgets',
  MULTI = 'multi',
  SELECT = 'select',
  SINGLE = 'single',
  TEXT = 'text',
}
